.hide-text {
    color: rgba(0, 0, 0, 0) !important;
}

.margin-t3 {
    margin-top: 3em;
}

.tenant-select-initials {
    height: 100% !important;
    border-radius: 0.375rem 0 0 0.375rem;
}

.clickable:hover {
    cursor: pointer;
}

.min-h-2 {
    min-height: 2em;
}
